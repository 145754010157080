// Product Card Calculations
window.areAddonsAdded = function(el){
  form = el.form;
  addonElements = form.querySelectorAll('input[type="hidden"][name^="order[add_ons]"][name$="[quantity]"]');
  addedAddon1 = null;
  if(addonElements != null){
    addonElements.forEach(eleme => {
      qty = Number(eleme.value);
      console.log(qty);
      if(qty > 0){
        addedAddon1 = eleme;
      }
    });
  }
  return addedAddon1;
}

window.updateAddonPrices = function(el){
  productCard = el.closest('.js-signle-product-card');
  productId = productCard.id.split('-')[2];
  addonsModal = productCard.querySelector(`#create-order-modal-all-${productId}`);
  orderForm = null;
  if(addonsModal != null) {
    orderForm = addonsModal.querySelector('#form_lease');
  }
  if(orderForm == null){
    if(addonsModal != null) {
      orderForm = addonsModal.querySelector('#form_rent');
      if(orderForm == null){
        orderForm = addonsModal.querySelector('#form_purchase');
      }
    }
  }
  var addonsForm = productCard.querySelector('#order-addons-form');
  if(addonsForm != null){
    var formData1 = new FormData(addonsForm);
    for (var pair of formData1.entries()) {
      if(pair[0] != 'authenticity_token' &&
        pair[0] != 'order[product_id]' &&
        pair[0] != 'order[order_type]' &&
        pair[0] != 'order[quantity]' &&
        pair[0] != 'lease_term_2')
      {
        createAddonFormFeilds(orderForm, pair);
      }
    }
  }
  var formData = new FormData(orderForm);
  addonPriceSendRequest(formData, orderForm);
}

// get addon price from server
async function addonPriceSendRequest(data1, form){

  csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  postHeaders = {'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json',
                'X-CSRF-Token': csrfToken };
  try{
    response = await fetch('/recalc_product_card_price', {
      method: 'POST',
      headers: postHeaders,
      body: data1
    });
    if (response.ok) {
      var data2 = await response.json();
      handleAddedAddonsPrices(data2, form, data1);
      setUpdatedLeaseTerm(productCard, data2['lease_interval']);
    }
  }
  catch (error) {
    console.error('An error occurred:', error);
  }
}

// Update prices on add assesories modal
function handleAddedAddonsPrices(data, form, formData1){

  console.log(data);
  closeModal = form.closest('.modal');
  if(closeModal == null){
    addOnDiv = form.querySelector('.addons-modal');
    if(addOnDiv != null){
      closeModal = addOnDiv.querySelector('.modal');}
  }
  rentAddonPrice = closeModal.querySelector('.js-addons-price-update-rent');
  purchaseAddonPrice = closeModal.querySelector('.js-addons-price-update-purchase');
  leaseAddonPrice = closeModal.querySelector('.js-addons-price-update-lease');
  rentForm = null;
  purchaseForm = null;
  leaseForm = null;
  if(rentAddonPrice != null){
    newPrice = ((parseFloat( data['rent'] ) * 100 )/100).toFixed(2);
    rentAddonPrice.innerHTML = `$${newPrice}`;
    rentForm = rentAddonPrice.closest('form');
  }
  if(purchaseAddonPrice != null){
    newPrice = ((parseFloat( data['purchase'] ) * 100 )/100).toFixed(2);
    purchaseAddonPrice.innerHTML = `$${newPrice}`;
    purchaseForm = purchaseAddonPrice.closest('form');
  }
  if(leaseAddonPrice != null){
    newPrice = ((parseFloat( data['lease'] ) * 100 )/100).toFixed(2);
    leaseAddonPrice.innerHTML = `$${newPrice}`;
    leaseForm = leaseAddonPrice.closest('form');
  }
  if(window.location.pathname.includes('show_quote')){
    return;
  }
  // copy formData1 to purchase, lease and rent forms
  for (var pair of formData1.entries()) {
    if(pair[0] != 'authenticity_token' &&
      pair[0] != 'order[product_id]' &&
      pair[0] != 'order[order_type]' &&
      pair[0] != 'order[quantity]' &&
      pair[0] != 'lease_term_2')
    {
      createAddonFormFeilds(rentForm, pair);
      createAddonFormFeilds(purchaseForm, pair);
      createAddonFormFeilds(leaseForm, pair);
    }
  }
  handleAddedAddonsPricesProductCard(data, form, formData1);
}

// Update form in add assesories modal
function createAddonFormFeilds(formEl, pair){
  hiddenField = document.createElement('input');
  hiddenField.type = 'hidden';
  hiddenField.name = pair[0];
  hiddenField.value = pair[1];
  if(formEl != null){
    attr1 = formEl.querySelector(`input[type="hidden"][name="${pair[0]}"`);
    if(attr1 == null){
      attr1 = formEl.querySelector(`input[type="hidden"][name="order[${pair[0]}]"`);
    }
    if(attr1 == null){
      formEl.appendChild(hiddenField);
    }else{
      attr1.value = pair[1];
    }
  }
}

//



function handleAddedAddonsPricesProductCard(data, form, formData1){
  console.log(data);
  closeModal = form.closest('.modal');
  productId = closeModal.id.split('-');
  productId = productId[productId.length -1 ];
  closeModal = closeModal.closest('.js-signle-product-card');

  if(closeModal == null){
    addOnDiv = form.querySelector('.addons-modal');
    if(addOnDiv != null){
      closeModal = addOnDiv.querySelector('.modal');}
  }
  rentAddonPrice = closeModal.querySelector('.js-rent-value-main');
  purchaseAddonPrice = closeModal.querySelector('.js-purchase-value-main');
  leaseAddonPrice = closeModal.querySelector('.js-lease-value-main');
  rentForm = null;
  purchaseForm = null;
  leaseForm = null;
  if(rentAddonPrice != null){
    newPrice = ((parseFloat( data['rent'] ) * 100 )/100).toFixed(2);
    rentAddonPrice.innerHTML = `$${newPrice}`;
    rentForm = rentAddonPrice.closest('form');
  }
  if(purchaseAddonPrice != null){
    newPrice = ((parseFloat( data['purchase'] ) * 100 )/100).toFixed(2);
    purchaseAddonPrice.innerHTML = `$${newPrice}`;
    purchaseForm = purchaseAddonPrice.closest('form');
  }
  if(leaseAddonPrice != null){
    newPrice = ((parseFloat( data['lease'] ) * 100 )/100).toFixed(2);
    leaseAddonPrice.innerHTML = `$${newPrice}`;
    leaseForm = leaseAddonPrice.closest('form');
  }
  if(window.location.pathname.includes('show_quote')){
    return;
  }
  // copy formData1 to purchase, lease and rent forms
  for (var pair of formData1.entries()) {
    if(pair[0] != 'authenticity_token' &&
      pair[0] != 'order[product_id]' &&
      pair[0] != 'order[order_type]' &&
      pair[0] != 'order[quantity]' &&
      pair[0] != 'lease_term_2')
    {
      createAddonFormFeilds(rentForm, pair);
      createAddonFormFeilds(purchaseForm, pair);
      createAddonFormFeilds(leaseForm, pair);
    }
  }
  // update prices on info modal
  updateProductInfoPrices(rentForm, productId, data, 'rent');
  updateProductInfoPrices(purchaseForm, productId, data, 'purchase');
  updateProductInfoPrices(leaseForm, productId, data, 'lease');
}

// main card product info prices update
function updateProductInfoPrices(form, productId, data, order_type){
  if(form == null){
    return;
  }
  productInfoModal = form.querySelector(`#product-info-${order_type}-${productId}`);
  if(productInfoModal != null){
    priceSpans = productInfoModal.querySelectorAll(`.js-pinfo-${order_type}-value`);
    newPrice = ((parseFloat( data[order_type] ) * 100 )/100).toFixed(2);
    if(priceSpans != null){
      priceSpans.forEach( priceSpan => {
        priceSpan.innerHTML = `$${newPrice}`;
        priceSpan.id = newPrice;
      });
    }
    if(order_type == 'lease'){
      lease_terms = [12, 24, 36, 48, 60, 63];
      productInfoModal.querySelector('.js-p-info-lease-term').innerHTML = lease_terms[Number(data['lease_interval'])];
      productInfoModal.querySelector('.js-pinfo-total-lease').innerHTML = `$${((parseFloat(newPrice) + parseFloat( data['service_agreement_cost'] )).toFixed(2)) * 100/ 100}`;
    }
    if(order_type == 'rent'){
      delivery = parseFloat(productInfoModal.querySelector('.js-delivery-price').id);
      instalation = parseFloat(productInfoModal.querySelector('.js-install-price').id);
      serviceValue = parseFloat( data['service_agreement_cost'] );
      newRentValue = parseFloat(newPrice);
      totalRental = parseFloat( newRentValue + serviceValue);
      productInfoModal.querySelectorAll('.js-pinfo-total-rent').forEach( tRent => {
        tRent.innerHTML = `$${totalRental.toFixed(2)}`;
      });
      rentGrandTotal = (( (parseFloat(totalRental * 2) * 100 / 100) + delivery + instalation) * 100/ 100).toFixed(2);
      productInfoModal.querySelectorAll('.js-pinfo-grandtotal-rent').forEach( gtRent => {
        gtRent.innerHTML = `$${rentGrandTotal}`;
      });
    }
    // deliveryPrice = Number(productInfoModal.querySelector('.js-delivery-price').id);
    // installPrice = Number(productInfoModal.querySelector('.js-install-price').id);
    // totalSpan = productInfoModal.querySelector('.js-grand-total');
    // if(totalSpan != null){
    //   price = `$${data[order_type]}`;
    //   totalSpan.innerHTML = price;
    // }
  }
}

//set Updated Lease Term
function setUpdatedLeaseTerm(productCard,leaseTerm) {
  productCard.querySelectorAll('#lease_term_1').forEach( leaseSlct => {
    leaseSlct.value = leaseTerm;
    leaseSlct.selectedIndex = Number(leaseTerm);
  });
  productCard.querySelectorAll('#lease_term').forEach( leaseSlct => {
    leaseSlct.value = leaseTerm;
    leaseSlct.selectedIndex = Number(leaseTerm);
  });
  productCard.querySelectorAll('#lease_term_2').forEach( leaseSlct => {
    leaseSlct.value = leaseTerm;
    leaseSlct.selectedIndex = Number(leaseTerm);
  });
}

//update lease term in modal
window.updateLeaseTermInModal = function(el){
  productCard = el.closest('.js-signle-product-card');
  leaseTerm = el.value;
  setUpdatedLeaseTerm(productCard,leaseTerm);
  updateAddonPrices(el);
}
