window.setLocalTimes = function() {
  var timeElements = document.querySelectorAll('.js-utc-to-local-time');
  if (timeElements != null) {
    timeElements.forEach(el1 => {
      // Sets local time in the element passed which have id set as UTC datetime
      el1.innerHTML = convertUTCtoLocalTime(el1.id);
    });
  }
}

window.setLocalDate = function() {
  var timeElements = document.querySelectorAll('.js-utc-to-local-date');
  if (timeElements != null) {
    timeElements.forEach(el1 => {
      // Sets local date in the element passed which have id set as UTC datetime
      el1.innerHTML = convertUTCtoLocalDate(el1.id);
    });
  }
}

window.convertUTCtoLocalTime = function(utcDateTime) {
  utcDateTime = utcDateTime.replace(' UTC', '');
  let date = new Date(utcDateTime.replace(' ', 'T') + 'Z');
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let strMinutes = minutes < 10 ? '0' + minutes : minutes;
  let strTime = hours + ':' + strMinutes + ' ' + ampm;
  return strTime;
}

window.convertUTCtoLocalDate = function(utcDateTime) {
  utcDateTime = utcDateTime.replace(' UTC', '');
  let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let date = new Date(utcDateTime.replace(' ', 'T') + 'Z');
  let month = monthNames[date.getMonth()];
  let day = date.getDate();
  day = day < 10 ? `0${day}` : day;
  let strDate = `${month} ${day}, ${date.getFullYear()}`;
  return strDate;
}
